<template>
    <div class="page-tech-requirements">
        <base-preloader-gif v-if="isLoading" :size="40" />
        <div class="content">
            <p v-html="techReq"></p>
        </div>
    </div>
</template>

<script>
import ServiceConstruction from "@/services/ServicesConstruction/ServiceConstruction";
import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
export default {
    name: 'PageTechRequirements',
    components: {BasePreloaderGif},
    data() {
        return {
            isLoading: true,
            techReq: null
        }
    },
    props: {
        oohdeskId: {
            type: String,
            default: ''
        }
    },
    mounted() {
        ServiceConstruction.getInfoByOohdeskId(this.oohdeskId, (data) => {
            const {technical_requirements: techReq = ""} = data;
            this.techReq = techReq.replace(/\n/g, '<br>');
            this.isLoading = false;
        })
    }
}
</script>

<style scoped>
.page-tech-requirements {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.content {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
    width: 100%;
    text-align: left;
}
</style>
